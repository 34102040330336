import PropTypes from 'prop-types';
import { Fragment, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';
import { ICONS_ALIASES } from '_constants/icons';
import Icon from '_components/atomic/Icon';

const { colors, devices } = theme;

const StyledContainer = styled.div`
  display: inline-flex;
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledStarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 24px;
  width: 24px;

  position: relative;

  @media ${devices.tablet} {
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledIconContainer = styled(forwardRef(({ active, width, ...props }, ref) => (<div ref={ref} {...props} />)))`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  z-index: 0;

  ${({ active }) =>
    active &&
    css`
      z-index: 1;
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

const Rating = ({ value, maxValue = 5 }) => {
  const innerMaxValue = Math.round(maxValue);
  const innerValue =
    value > innerMaxValue ? innerMaxValue : value < 0 ? 0 : value;

  return (
    <StyledContainer>
      {Array.from({ length: innerMaxValue }, (_, idx) => (
        <Fragment key={`rating-star-${idx}`}>
          <StyledStarContainer>
            <StyledIconContainer hidden={innerValue >= idx + 1}>
              <span>
                <Icon alias={ICONS_ALIASES.star} color={colors.gray[400]} />
              </span>
            </StyledIconContainer>
            <StyledIconContainer
              active
              hidden={innerValue < idx}
              width={`${
                innerValue - idx - 1 >= 0
                  ? 100
                  : (1 + innerValue - idx - 1) * 100
              }%`}
            >
              <span>
                <Icon alias={ICONS_ALIASES.star} color={colors.indigo[600]} />
              </span>
            </StyledIconContainer>
          </StyledStarContainer>
        </Fragment>
      ))}
    </StyledContainer>
  );
};

Rating.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
};

export default Rating;
