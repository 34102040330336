import { NextSeo } from 'next-seo';
import HomePage from '_components/composed/pages/HomePage';

export default function Home() {
  return (
    <>
      <NextSeo
        title="Incorpified - Official Site | Register Your LLC Online Today"
        description="Incorpified - Streamline your business incorporation process. Get started with LLC formation, trademark registration, and more with our fast and affordable services."
      />
      <HomePage />
    </>
  );
}
