/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import { theme } from '_constants/theme';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { config } from '_config';

const { colors, devices, fontSizes, spaces } = theme;

const StyledBottomBannerSection = styled(
  forwardRef(({ moreSpace, ...props }, ref) => <div ref={ref} {...props} />)
)`
  display: flex;
  justify-content: space-between;
  background: ${colors.indigo[600]};
  border-radius: 40px;
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;
  padding-left: ${spaces.large14X}px;
  padding-right: ${spaces.large14X}px;
  padding-top: ${spaces.large9X}px;
  padding-bottom: ${spaces.large9X}px;
  max-height: 279px;
  .header {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: ${fontSizes.large11X}px;
    line-height: 130%;
    color: ${colors.white};
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: ${({ moreSpace }) =>
      moreSpace ? spaces.large4X : spaces.medium}px;
  }
  .footer {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSizes.large}px;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .column1 {
    width: 100%;
    color: white;
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSizes.medium}px;
    line-height: 150%;
    z-index: 1;
  }
  .column2 img {
    border-bottom-right-radius: 40px;
    position: absolute;
    transform: translate(-288px, -150px);
    min-width: 368px;
    min-height: 369px;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    white-space: nowrap;
  }
  @media ${devices.tablet} {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0px;
    box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.05);
    background: ${colors.indigo[600]} no-repeat;
    background-position: 100% 100%;
    min-height: 290px;
    background-image: ${`url(${config.basePath}/images/bottom-banner-rocket-mobile.svg)`};
    padding-left: ${spaces.regular}px;
    padding-right: ${spaces.regular}px;
    padding-top: ${spaces.large}px;
    padding-bottom: ${spaces.large}px;
    align-items: center;
    .header {
      font-size: ${fontSizes.large2X}px;
      line-height: 150%;
      margin-bottom: ${({ moreSpace }) =>
        moreSpace ? spaces.regular : spaces.smallX}px;
      margin-right: ${spaces.large24X}px;
    }
    .footer {
      font-size: ${fontSizes.regular}px;
      line-height: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      p {
        margin-right: ${spaces.large24X}px;
      }
    }
    .buttonsContainer {
      flex-direction: column;
      width: 100%;
      button {
        width: 100%;
        justify-content: center;
      }
    }
    .column2 {
      display: none;
    }
  }
`;
const BottomBannerSection = ({ header, footer, moreSpace }) => {
  return (
    <StyledBottomBannerSection moreSpace={moreSpace}>
      <div className="column1">
        <div className="header">{header}</div>
        <div className="footer">{footer}</div>
      </div>
      <div className="column2">
        <picture>
          <source
            srcSet={`${config.basePath}/images/bottom-banner-rocket.svg`}
            type="image/svg+xml"
          />
          <img
            src={`${config.basePath}/images/bottom-banner-rocket.svg`}
            title="Rocket Ship Take off Image"
            alt="Rocket Ship Take off Image"
          />
        </picture>
      </div>
    </StyledBottomBannerSection>
  );
};

BottomBannerSection.propTypes = {
  title: PropTypes.node,
  footer: PropTypes.node,
  moreSpace: PropTypes.bool,
};

export default BottomBannerSection;
