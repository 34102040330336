import PropTypes from 'prop-types';
import { useMemo, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

import Button from '_components/atomic/Button';
import Icon from '_components/atomic/Icon';

const { colors, devices, fontSizes, spaces } = theme;

const StyledEntityTypeSection = styled.div`
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tablet} {
    padding: ${spaces.regular}px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${spaces.large6X}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large11X}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[800]};

  @media ${devices.tablet} {
    margin-bottom: ${spaces.medium}px;
    font-size: ${fontSizes.large2X}px;
  }
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${spaces.largeX + 2}px;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: center;
    gap: ${spaces.small - 2}px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spaces.large6X}px;

  @media ${devices.tablet} {
    padding-top: ${spaces.large3X}px;
  }
`;

const StyledInfoText = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[700]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const StyledInfoAction = styled.div`
  padding-top: ${spaces.largeX + 2}px;

  @media ${devices.tablet} {
    padding-top: ${spaces.smallX + 2}px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCard = styled(forwardRef(({ active, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spaces.largeX + 2}px;

  width: ${`calc((100% - ${(spaces.largeX + 2) * 3}px) / 4)`};

  padding: ${spaces.medium}px;

  background: ${colors.gray[50]};
  border: 1px solid ${colors.gray[200]};
  border-radius: 16px;

  color: ${colors.gray[800]};

  cursor: pointer;

  &:hover {
    background: ${colors.indigo[100]};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${colors.indigo[600]};
      color: ${colors.white};
    `}

  @media ${devices.tablet} {
    gap: ${spaces.small - 2}px;
    width: 100%;
    margin-top: 0;
    padding: ${spaces.medium}px;
    border-radius: 16px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCardIcon = styled(forwardRef(({ desktop, mobile, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: center;

  min-height: 50px;
  min-width: 50px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    min-height: 40px;
    min-width: 40px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `}
  }
`;

const StyledCardTitle = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large}px;
  text-align: center;

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const EntityTypeSection = ({ items = [], selectedKey, onSelect }) => {
  const selectedItem = useMemo(
    () => items?.find(({ key }) => key === selectedKey),
    [selectedKey, items]
  );

  return (
    <StyledEntityTypeSection>
      <StyledTitle>What Entity Type is Right For You?</StyledTitle>
      <StyledBody>
        {items?.map(({ key, title, iconAlias, iconPalette }) => (
          <StyledCard key={key} active={key === selectedKey}>
            <StyledCardIcon desktop>
              <Icon
                alias={iconAlias}
                palette={iconPalette}
                mixBlendMode="luminosity"
                height={50}
                width={50}
              />
            </StyledCardIcon>
            <StyledCardIcon mobile>
              <Icon
                alias={iconAlias}
                palette={iconPalette}
                mixBlendMode="luminosity"
                height={40}
                width={40}
              />
            </StyledCardIcon>
            <StyledCardTitle>{title}</StyledCardTitle>
          </StyledCard>
        ))}
      </StyledBody>
      <StyledInfo>
        <StyledInfoText>{selectedItem?.description}</StyledInfoText>
        <StyledInfoAction>
          <Button
            outlined
            color={theme.colors.indigo[500]}
            onClick={selectedItem?.button?.onClick}
          >
            {selectedItem?.button?.title}
          </Button>
        </StyledInfoAction>
      </StyledInfo>
    </StyledEntityTypeSection>
  );
};

EntityTypeSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.node,
      iconAlias: PropTypes.string,
      iconPalette: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.node,
      button: PropTypes.shape({
        title: PropTypes.node,
        onClick: PropTypes.func,
      }),
    })
  ),
  selectedKey: PropTypes.string,
  onSelect: PropTypes.func,
};

export default EntityTypeSection;
