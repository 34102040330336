/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { theme } from '_constants/theme';
import { ICONS_ALIASES } from '_constants/icons';
import { ROUTES } from '_constants/routes';

import Icon from '_components/atomic/Icon';
import Button from '_components/atomic/Button';
import Rating from '_components/atomic/Rating';

const { publicRuntimeConfig } = getConfig();
const { colors, devices, fontSizes, spaces } = theme;

const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${colors.indigo[25]};
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    z-index: -1;
  }

  @media ${devices.tablet} {
    &::before {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
`;

const StyledBannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: column;
    padding-left: ${spaces.regular}px;
    padding-right: ${spaces.regular}px;
    padding-top: ${spaces.large}px;
    padding-bottom: ${spaces.large}px;
  }
`;

const StyledBannerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 50%;
  min-height: 720px;
  position: relative;

  &:first-child {
    padding-top: ${spaces.large19X}px;
    padding-right: ${spaces.large10X}px;
  }

  &:last-child {
    justify-content: flex-end;
  }

  @media ${devices.tablet} {
    min-height: 405px;
    width: 100%;

    &:first-child {
      padding-top: 0;
      padding-right: 0;
    }
  }
`;

const StyledBackImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding-top: ${spaces.large7X}px;

  position: absolute;

  z-index: -1;

  img {
    min-height: 582px;
  }

  @media ${devices.tablet} {
    padding-top: ${spaces.largeX}px;

    img {
      min-height: 358px;
      width: 100%;
    }
  }
`;

const StyledFrontImageContainer = styled(
  forwardRef(({ desktop, mobile, rightSpace, ...props }, ref) => (
    <div ref={ref} {...props} />
  ))
)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  position: absolute;

  z-index: -1;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `}
  }
`;

const StyledContentBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${spaces.medium}px;

  @media ${devices.tablet} {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledTitle = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large11X}px;
  line-height: 130%;
  color: ${colors.gray[800]};

  span {
    color: ${colors.indigo[600]};
  }

  @media ${devices.tablet} {
    font-size: ${fontSizes.large8X}px;
    line-height: 140%;
  }
`;

const StyledSubtitle = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large2X}px;
  line-height: 150%;
  color: ${colors.indigo[600]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const StyledText = styled(
  forwardRef(({ color, ...props }, ref) => <div ref={ref} {...props} />)
)`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium}px;
  line-height: 150%;
  color: ${colors.gray[700]};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const StyledSubtext = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.regular}px;
  line-height: 150%;
  color: ${colors.gray[900]};

  span {
    font-weight: 500;
    color: ${colors.indigo[600]};
  }

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular - 2}px;
  }
`;

const StyledRatingCard = styled.div`
  display: flex;
  padding-top: ${spaces.large}px;
  padding-bottom: ${spaces.large}px;

  @media ${devices.tablet} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledRatingCardContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${spaces.small2X}px;
`;

const StyledRatingCardIconContainer = styled.div`
  padding: ${spaces.small}px;
  margin-right: ${spaces.smallX}px;
  background: ${colors.indigo[50]};
  border-radius: 10px;
`;

const StyledHelpCard = styled.div`
  display: flex;
  gap: 30px;
  width: fit-content;
  margin-bottom: ${spaces.large17X}px;
  padding: ${spaces.large}px;
  background-color: ${colors.white};
  border-radius: 16px;

  @media ${devices.tablet} {
    justify-content: space-between;
    gap: 0;
    width: 100%;
    margin-bottom: 0;
    padding: ${spaces.regular}px;
  }
`;

const StyledHelpCardContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HomeBannerSection = () => {
  const router = useRouter();

  return (
    <StyledBanner>
      <StyledBannerContainer>
        <StyledBannerColumn>
          <StyledContentBlock>
            <StyledTitle>
              Form Your Business Online in Just <span>a Few Minutes</span>
            </StyledTitle>
          </StyledContentBlock>
          <StyledContentBlock>
            <StyledText>
              We streamline the entire incorporation process from start to
              finish. Get back to doing what you love, let us take care of this
              daunting process.
            </StyledText>
          </StyledContentBlock>
          <StyledContentBlock>
            <StyledRatingCard>
              <StyledRatingCardContentBlock>
                <StyledRatingCardIconContainer>
                  <Icon
                    alias={ICONS_ALIASES.medalStar}
                    color={colors.indigo[600]}
                  />
                </StyledRatingCardIconContainer>
              </StyledRatingCardContentBlock>
              <StyledRatingCardContentBlock>
                <StyledSubtext>
                  Business Owners Trust Us to Get the Job Done Right
                </StyledSubtext>
                <StyledSubtext>
                  <Rating maxValue={5} value={4.5} />
                  &nbsp;<span>4.5</span>
                  &nbsp;<span>(2,218 Reviews)</span>
                </StyledSubtext>
              </StyledRatingCardContentBlock>
            </StyledRatingCard>
          </StyledContentBlock>
          <StyledContentBlock>
            <Button
              suffix={
                <Icon alias={ICONS_ALIASES.arrowRight} color={colors.white} />
              }
              color={colors.indigo[600]}
              onClick={() => router.push(ROUTES.llcIncorporation)}
            >
              Start Your Business
            </Button>
          </StyledContentBlock>
        </StyledBannerColumn>
        <StyledBannerColumn>
          <StyledBackImageContainer>
            <picture>
              <source
                srcSet={`${publicRuntimeConfig.basePath}/images/home-banner-background.svg`}
                type="image/svg+xml"
              />
              <img
                src={`${publicRuntimeConfig.basePath}/images/home-banner-background.svg`}
                title="LLC Take off Image"
                alt="LLC Take off Image"
              />
            </picture>
          </StyledBackImageContainer>
          <StyledFrontImageContainer desktop>
            <picture>
              <source
                srcSet={`${publicRuntimeConfig.basePath}/images/home-business-man-desktop.png`}
                type="image/png"
              />
              <img
                src={`${publicRuntimeConfig.basePath}/images/home-business-man-desktop.png`}
                title="Professional Business Owner Image"
                alt="Professional Business Owner Image"
              />
            </picture>
          </StyledFrontImageContainer>
          <StyledFrontImageContainer mobile>
            <picture>
              <source
                srcSet={`${publicRuntimeConfig.basePath}/images/home-business-man-mobile.png`}
                type="image/png"
              />
              <img
                src={`${publicRuntimeConfig.basePath}/images/home-business-man-mobile.png`}
                title="Professional Business Owner Image - Mobile"
                alt="Professional Business Owner Image - Mobile"
              />
            </picture>
          </StyledFrontImageContainer>
          <StyledHelpCard>
            <StyledHelpCardContentBlock>
              <StyledSubtitle>Already have a business?</StyledSubtitle>
              <StyledText>Find out how we can help</StyledText>
            </StyledHelpCardContentBlock>
            <StyledHelpCardContentBlock>
              <Button
                outlined
                color={colors.indigo[600]}
                onClick={() => router.push(ROUTES.services)}
              >
                Our Services
              </Button>
            </StyledHelpCardContentBlock>
          </StyledHelpCard>
        </StyledBannerColumn>
      </StyledBannerContainer>
    </StyledBanner>
  );
};

export default HomeBannerSection;
