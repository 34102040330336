import styled from 'styled-components';
import { useRouter } from 'next/router';

import { config } from '_config';
import { theme } from '_constants/theme';
import { ROUTES } from '_constants/routes';
import { ICONS_ALIASES, ICONS_PALETTE } from '_constants/icons';

import { Divider } from '_components/atomic/layouts';
import Circle from '_components/atomic/Circle';
import Dots from '_components/atomic/Dots';
import Button from '_components/atomic/Button';
import Icon from '_components/atomic/Icon';
import HomeBannerSection from '_components/composed/sections/HomeBannerSection';
import EntityTypeSection from '_components/composed/sections/EntityTypeSection';
import HowSection from '_components/composed/sections/HowSection';
import FancyList from '_components/atomic/FancyList';
import WhyIncorpifySection from '_components/composed/sections/WhyIncorpifySection';
import ChooseServiceSection from '_components/composed/sections/ChooseServiceSection';
import BottomBannerSection from '_components/composed/sections/BottomBannerSection';

const { colors, spaces } = theme;

const StyledHomePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const HomePage = () => {
  const router = useRouter();

  return (
    <StyledHomePage>
      <HomeBannerSection />

      <Divider size={spaces.large24X} desktop />
      <Divider size={spaces.large} mobile />

      <EntityTypeSection
        items={[
          {
            key: '1',
            title: 'Limited Liability Company',
            iconAlias: ICONS_ALIASES.career,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.career],
            description: (
              <>
                A limited liability company (LLC) is a business structure in the
                U.S. that protects its owners from personal responsibility for
                its debts or liabilities. Limited liability companies are hybrid
                entities that combine the characteristics of a corporation with
                those of a partnership or sole proprietorship.
              </>
            ),
            button: {
              title: 'Learn more about LLC’s',
              onClick: () => {},
            },
          },
          {
            key: '2',
            title: 'Sole Proprietor',
            iconAlias: ICONS_ALIASES.freelance,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.freelance],
            description: <>A Sole Proprietor is ..</>,
            button: {
              title: 'Learn more about Sole Proprietor',
              onClick: () => {},
            },
          },
          {
            key: '3',
            title: 'Corporation',
            iconAlias: ICONS_ALIASES.corporate,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.corporate],
            description: <>Corporation is ..</>,
            button: {
              title: 'Learn more about Corporation',
              onClick: () => {},
            },
          },
          {
            key: '4',
            title: 'Non profit',
            iconAlias: ICONS_ALIASES.kindness,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.kindness],
            description: <>Non profit is ..</>,
            button: {
              title: 'Learn more about Non profit',
              onClick: () => {},
            },
          },
        ]}
        selectedKey={'1'}
      />

      <Divider size={spaces.large24X} desktop />
      <Divider size={spaces.large} mobile />

      <HowSection
        title="How can we help you?"
        leftImage={`${config.basePath}/images/home-how-image.png`}
        rightPart={
          <FancyList
            items={[
              {
                title: 'I need to start a business',
                description: 'I’m ready to start incorporating my business',
                icon: ICONS_ALIASES.rocket,
                iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.rocket],
                color: colors.indigo[25],
                textColor: colors.indigo[500],
              },
              {
                title: 'I need help managing my business',
                description:
                  'I’m looking for services that can help manage my business',
                icon: ICONS_ALIASES.verify,
                iconPalette: ICONS_PALETTE.green[ICONS_ALIASES.verify],
                color: colors.green[50],
                textColor: colors.green[500],
              },
              {
                title: 'I need to register a trademark',
                description: 'I want to protect my intellectual property',
                icon: ICONS_ALIASES.shield,
                iconPalette: ICONS_PALETTE.blue[ICONS_ALIASES.shield],
                color: colors.blue[25],
                textColor: colors.blue[500],
              },
              {
                title: 'I have questions',
                description:
                  'I have questions about incorporating, business services, or about Incorpified',
                icon: ICONS_ALIASES.question,
                iconPalette: ICONS_PALETTE.orange[ICONS_ALIASES.question],
                color: colors.orange[50],
                textColor: colors.orange[500],
              },
            ]}
          />
        }
      />

      <Divider size={spaces.large27X} desktop />
      <Divider size={spaces.large} mobile />

      <WhyIncorpifySection />

      <Divider size={spaces.large24X} desktop />
      <Divider size={spaces.large} mobile />

      <ChooseServiceSection
        items={[
          {
            title: 'Bookeeping',
            icon: ICONS_ALIASES.career,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.career],
            route: ROUTES.bookkeeping,
          },
          {
            title: 'Registered Agent',
            icon: ICONS_ALIASES.clipboard,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.clipboard],
            route: ROUTES.registeredAgent,
          },
          {
            title: 'Tax Preparation',
            icon: ICONS_ALIASES.taxes2,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.taxes2],
            route: ROUTES.einTaxId,
          },
          {
            title: 'Payroll Services',
            icon: ICONS_ALIASES.salary,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.salary],
            route: ROUTES.payroll,
          },
          {
            title: 'Merchant Services',
            icon: ICONS_ALIASES.grocery,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.grocery],
            route: ROUTES.merchantProcessing,
          },
          {
            title: 'Trademark',
            icon: ICONS_ALIASES.favorite,
            iconPalette: ICONS_PALETTE.indigo[ICONS_ALIASES.favorite],
            route: ROUTES.trademarking,
          },
        ]}
      />

      <Divider size={spaces.large20X * 2} desktop />
      <Divider size={spaces.large} mobile />

      <BottomBannerSection
        header={<>Get Your Business Incorporated Today</>}
        footer={
          <>
            <p>Here are some services that business owners use</p>
            <div className="buttonsContainer">
              <Button
                suffix={
                  <Icon
                    alias={ICONS_ALIASES.arrowRight}
                    color={colors.indigo[600]}
                  />
                }
                color={colors.white}
                textColor={colors.indigo[600]}
                hoverColor={colors.white}
                hoverTextColor={colors.indigo[600]}
                onClick={() => router.push(ROUTES.llcIncorporation)}
              >
                Start Your LLC
              </Button>
              <Button
                suffix={
                  <Icon
                    alias={ICONS_ALIASES.arrowRight}
                    color={colors.indigo[600]}
                  />
                }
                color={colors.white}
                textColor={colors.indigo[600]}
                hoverColor={colors.white}
                hoverTextColor={colors.indigo[600]}
              >
                Start Your Corporation
              </Button>
            </div>
          </>
        }
        moreSpace
      />

      <Divider size={spaces.large24X} desktop />
      <Divider size={spaces.large} mobile />

      <Dots
        color={theme.colors.indigo[500]}
        left={80}
        top={`calc(43% + 15px)`}
      />
      <Circle
        gradient={{
          from: theme.colors.indigo[300],
          to: theme.colors.indigo[50],
        }}
        size={686}
        left={-178}
        top={`calc(34% + 40px)`}
      />

      <Dots
        color={theme.colors.indigo[500]}
        right={116}
        top={`calc(51% + 30px)`}
      />
      <Circle
        gradient={{
          from: theme.colors.indigo[300],
          to: theme.colors.indigo[50],
        }}
        size={686}
        rotate={180}
        right={-172}
        top={`calc(48% + 32px)`}
      />
    </StyledHomePage>
  );
};

export default HomePage;
