import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';
import { ICONS_ALIASES, ICONS_PALETTE } from '_constants/icons';

import Icon from '_components/atomic/Icon';

const { colors, devices, fontSizes, spaces } = theme;

const StyledWhyIncorpifySection = styled.div`
  max-width: ${spaces.large24X * 10 + 80}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tablet} {
    padding: ${spaces.regular}px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large11X}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[800]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.large2X}px;
  }
`;

const StyledSubtitle = styled.div`
  margin-bottom: ${spaces.large6X + 2}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[700]};

  @media ${devices.tablet} {
    margin-bottom: ${spaces.medium}px;
    font-size: ${fontSizes.regular}px;
  }
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spaces.largeX + 2}px;

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCard = styled(forwardRef(({ topSpaceDesktop, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${spaces.largeX + 2}px;

  background: ${`linear-gradient(180deg, ${colors.indigo[50]} 0%, ${colors.white} 100%)`};
  border-radius: 30px;

  ${({ topSpaceDesktop }) =>
    topSpaceDesktop &&
    css`
      margin-top: ${topSpaceDesktop}px;
    `}

  @media ${devices.tablet} {
    margin-top: 0;
    padding: ${spaces.medium}px;
    border-radius: 16px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCardIcon = styled(forwardRef(({ desktop, mobile, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: center;

  min-height: 90px;
  min-height: 90px;

  padding-top: ${spaces.smallX + 2}px;
  margin-bottom: ${spaces.large4X}px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    min-height: 50px;
    min-height: 50px;

    margin-bottom: ${spaces.medium}px;

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `}
  }
`;

const StyledCardTitle = styled.div`
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large2X}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[800]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.medium}px;
  }
`;

const StyledCardText = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[700]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const WhyIncorpifySection = () => {
  return (
    <StyledWhyIncorpifySection>
      <StyledTitle>Why Should You Use Incorpified?</StyledTitle>
      <StyledSubtitle>
        Business Owners Trust Us to Get the Job Done Right
      </StyledSubtitle>
      <StyledBody>
        <StyledCard>
          <StyledCardIcon desktop>
            <Icon
              alias={ICONS_ALIASES.saveEnergy}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.saveEnergy]}
            />
          </StyledCardIcon>
          <StyledCardIcon mobile>
            <Icon
              alias={ICONS_ALIASES.saveEnergy}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.saveEnergy]}
              height={spaces.large6X + 2}
              width={spaces.large6X + 2}
            />
          </StyledCardIcon>
          <StyledCardTitle>Affordable</StyledCardTitle>
          <StyledCardText>
            Our Business Specialists will form your new business the correct
            way, saving you time and money by avoiding costly errors. Let us
            handle your business filings while you focus on growing your
            business.
          </StyledCardText>
        </StyledCard>
        <StyledCard topSpaceDesktop={spaces.large6X + 2}>
          <StyledCardIcon desktop>
            <Icon
              alias={ICONS_ALIASES.quick}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.quick]}
            />
          </StyledCardIcon>
          <StyledCardIcon mobile>
            <Icon
              alias={ICONS_ALIASES.quick}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.quick]}
              height={spaces.large6X + 2}
              width={spaces.large6X + 2}
            />
          </StyledCardIcon>
          <StyledCardTitle>Fast Processing</StyledCardTitle>
          <StyledCardText>
            When you place your order through Incorpified we immediately start
            the process of forming your new business. Our processing times are
            some of the fastest in the industry. This allows you to get your
            business up and running quickly.
          </StyledCardText>
        </StyledCard>
        <StyledCard>
          <StyledCardIcon desktop>
            <Icon
              alias={ICONS_ALIASES.headphones}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.headphones]}
            />
          </StyledCardIcon>
          <StyledCardIcon mobile>
            <Icon
              alias={ICONS_ALIASES.headphones}
              palette={ICONS_PALETTE.indigo[ICONS_ALIASES.headphones]}
              height={spaces.large6X + 2}
              width={spaces.large6X + 2}
            />
          </StyledCardIcon>
          <StyledCardTitle>Dedicated Agents</StyledCardTitle>
          <StyledCardText>
            Each and every one of our customers is assigned a personal Business
            Specialist. You have their direct phone number and email. Have
            questions? Just call your personal Business Specialist. No need to
            wait in a pool of phone calls.
          </StyledCardText>
        </StyledCard>
      </StyledBody>
    </StyledWhyIncorpifySection>
  );
};

export default WhyIncorpifySection;
