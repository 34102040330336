import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { theme } from '_constants/theme';

import Icon from '_components/atomic/Icon';

const { colors, devices, fontSizes, spaces } = theme;

const StyledChooseServiceSection = styled.div`
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tablet} {
    padding: ${spaces.regular}px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${spaces.medium}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large11X}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[800]};

  @media ${devices.tablet} {
    font-size: ${fontSizes.large2X}px;
  }
`;

const StyledSubtitle = styled.div`
  margin-bottom: ${spaces.large6X + 2}px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.medium}px;
  line-height: 150%;
  text-align: center;
  color: ${colors.gray[700]};

  @media ${devices.tablet} {
    margin-bottom: ${spaces.medium}px;
    font-size: ${fontSizes.regular}px;
  }
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${spaces.largeX + 2}px;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: center;
    gap: ${spaces.small - 2}px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spaces.largeX + 2}px;

  width: ${`calc((100% - ${(spaces.largeX + 2) * 2}px) / 3)`};

  padding: ${spaces.medium}px;

  background: ${colors.gray[50]};
  border: 1px solid ${colors.gray[200]};
  border-radius: 16px;

  color: ${colors.gray[800]};

  cursor: pointer;

  &:hover {
    background: ${colors.indigo[600]};
    color: ${colors.white};
  }

  @media ${devices.tablet} {
    gap: ${spaces.small - 2}px;
    width: 100%;
    margin-top: 0;
    padding: ${spaces.medium}px;
    border-radius: 16px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCardIcon = styled(forwardRef(({ desktop, mobile, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  justify-content: center;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `}
  }
`;

const StyledCardTitle = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: ${fontSizes.large}px;
  text-align: center;

  @media ${devices.tablet} {
    font-size: ${fontSizes.regular}px;
  }
`;

const ChooseServiceSection = ({ items }) => {
  const router = useRouter();

  return (
    <StyledChooseServiceSection>
      <StyledTitle>Already Incorporated?</StyledTitle>
      <StyledSubtitle>
        Here are some services that business owners use
      </StyledSubtitle>
      <StyledBody>
        {items?.map(({ title, icon, iconPalette, route }) => (
          <StyledCard key={route} onClick={() => router.push(route)}>
            <StyledCardIcon desktop>
              <Icon
                alias={icon}
                palette={iconPalette}
                mixBlendMode="luminosity"
                height={50}
                width={50}
              />
            </StyledCardIcon>
            <StyledCardIcon mobile>
              <Icon
                alias={icon}
                palette={iconPalette}
                mixBlendMode="luminosity"
                height={40}
                width={40}
              />
            </StyledCardIcon>
            <StyledCardTitle>{title}</StyledCardTitle>
          </StyledCard>
        ))}
      </StyledBody>
    </StyledChooseServiceSection>
  );
};

ChooseServiceSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      icon: PropTypes.string,
      iconPalette: PropTypes.arrayOf(PropTypes.string),
      route: PropTypes.string,
    })
  ),
};

export default ChooseServiceSection;
